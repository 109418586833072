import React from 'react';
import PropTypes from 'prop-types';
import NewBankAccountsList from '@fingo/lib/components/lists/NewBankAccountList';
import { Box, Typography, Stack } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const AddInvoicesToPurchaseOrdersDialogStepTwo = ({
  statistics,
  accounts,
  loading,
  account,
  setAccount,
  company,
  refetch,
}) => (
  <>
    <Stack spacing={1} alignItems="center">
      {statistics.map(({ label, value }) => (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width="60%"
          key={label}
        >
          <Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>{label}:</Typography>
          <Typography sx={{ fontSize: 14 }}>{value}</Typography>
        </Stack>
      ))}
    </Stack>
    <Stack width="inherit">
      <Typography sx={{ fontWeight: 'bold', fontSize: 16, mt: 4 }}>Selecciona una cuenta bancaria para poder operar:</Typography>
      <NewBankAccountsList
        accounts={accounts}
        loading={loading}
        company={company}
        withSelect
        selectedAccount={account}
        setSelectedAccount={setAccount}
        addable={!accounts.length}
        editable={false}
        deletable={false}
        xs={12}
        refetch={refetch}
      />
      <Box sx={{ display: 'flex', alignItems: 'center', width: 'inherit', alignSelf: 'center' }}>
        <WarningAmberIcon />
        <Typography ml={1}>
          Los montos finales están sujetos a la evaluación de riesgo y la fecha de operación.
        </Typography>
      </Box>
    </Stack>
  </>
);

AddInvoicesToPurchaseOrdersDialogStepTwo.propTypes = {
  statistics: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  accounts: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  account: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  loading: PropTypes.bool.isRequired,
  company: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  setAccount: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default AddInvoicesToPurchaseOrdersDialogStepTwo;
