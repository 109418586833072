import React from 'react';
import PropTypes from 'prop-types';
import { useBooleanState } from '@fingo/lib/hooks';
import { Button } from '@mui/material';
import PostAdd from '@mui/icons-material/PostAdd';
import AddInvoicesToPurchaseOrdersDialog from './AddInvoicesToPurchaseOrdersDialog';

const PendingPurchaseOrdersActions = ({
  selectedPurchaseOrder,
  setSelectedPurchaseOrderIds,
  company,
  disabled,
  refetch,
}) => {
  const [
    openAddInvoicesToPurchaseOrdersDialog,
    toggleOpenAddInvoicesToPurchaseOrdersDialog,
  ] = useBooleanState(false);

  return (
    <>
      <Button
        color="primary"
        size="small"
        variant="contained"
        onClick={() => toggleOpenAddInvoicesToPurchaseOrdersDialog()}
        disabled={disabled || selectedPurchaseOrder.length === 0}
        startIcon={<PostAdd />}
        sx={{ m: 0.3, height: 25, width: '100%', justifyContent: 'flex-start' }}
      >
        Agregar múltiples facturas
      </Button>
      {openAddInvoicesToPurchaseOrdersDialog && (
        <AddInvoicesToPurchaseOrdersDialog
          open={openAddInvoicesToPurchaseOrdersDialog}
          setOpen={toggleOpenAddInvoicesToPurchaseOrdersDialog}
          purchaseOrders={selectedPurchaseOrder}
          setSelectedPurchaseOrderIds={setSelectedPurchaseOrderIds}
          company={company}
          refetch={refetch}
        />
      )}
    </>
  );
};

PendingPurchaseOrdersActions.propTypes = {
  refetch: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
  company: PropTypes.string,
  selectedPurchaseOrder: PropTypes.arrayOf(PropTypes.shape({
    company: PropTypes.string,
  })),
  setSelectedPurchaseOrderIds: PropTypes.func.isRequired,
};

PendingPurchaseOrdersActions.defaultProps = {
  refetch: () => {},
  selectedPurchaseOrder: [],
  company: '',
};

export default PendingPurchaseOrdersActions;
