import RaiseOperativeRequestIconButton from '@fingo/lib/views/operativeRequest/RaiseOperativeRequestIconButton';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

// in the first version the header components
// OrderingOffersHeader, SimulationsHeader, OrderingCollectionsHeaders and OrderingOperationsHeaders
// are exactly the same, but in the future thay could have different acctions
const OrderingOffersHeader = ({ selectedPurchaseOrderIds, setSelectedPurchaseOrderIds }) => (
  <Grid>
    <RaiseOperativeRequestIconButton
      documentType="ordering"
      selectedInvoices={selectedPurchaseOrderIds}
      setDocumentIds={setSelectedPurchaseOrderIds}
    />
  </Grid>
);

OrderingOffersHeader.propTypes = {
  selectedPurchaseOrderIds: PropTypes.arrayOf(
    PropTypes.number,
  ).isRequired,
  setSelectedPurchaseOrderIds: PropTypes.func,
};

OrderingOffersHeader.defaultProps = {
  setSelectedPurchaseOrderIds: () => null,
};

export default OrderingOffersHeader;
